export function validateDonation(amount) {
  if(amount <= 0) return false;
  return true;
}

export function validateContact(firstName, lastName, email, address, zip, city, state, phone) {
  if(!firstName || !lastName || !email || !address || !zip || !city || !state || !phone) return false;
  return true;

}

export function validateCreditCard(number, expiry, cvc, firstName, lastName){
  if(!number || !expiry || !cvc || !firstName || !lastName) return false;
  return true;
}
