import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSpring, animated } from "react-spring";
import Fade from "react-reveal/Fade";
import Card from 'react-credit-cards';
import $ from 'jquery';
import 'react-credit-cards/es/styles-compiled.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { async as sanitizeAsync } from 'pci-dss-sanitizer';
import CreditCardInput from 'react-credit-card-input';
import {validateContact, validateDonation, validateCreditCard} from './validateInputs';
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

const MY_API_KEY = "AIzaSyAFpoWSkM02BB5ORBgJ1RiIqype-mI-XqY" // fake

export default class Import extends Component {
  constructor() {
    super();
    this.state = {
      search: "",
      value: "",
      amount: 500,
      monthly: true,
      page: "Donation",
      number: "",
      firstName: "",
      lastName: "",
      expiry: "",
      cvc: "",
      issuer: "",
      focused: "",
    };
  }
  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log({value,name});
    this.setState({ [name]: value });
  }
  handleMapsChange = e => {
      this.setState({search: e.target.value, value: e.target.value})
  }
  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    try {
      let address = geocodedPrediction.address_components[0].long_name + ' ' + geocodedPrediction.address_components[1].long_name;
      let state = geocodedPrediction.address_components[5].short_name;
      let city = geocodedPrediction.address_components[3].long_name;
      let zip = geocodedPrediction.address_components[7].long_name;
      this.setState({address, zip, city, state,search:''})
    }
    catch(err){

    }
  }
  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };
  submitPayment(event) {
    if (event && event.preventDefault) event.preventDefault();
    const {firstName, lastName, email, address, zip, city, state, phone, number, expiry, cvc, monthly, unit} = this.state;

    console.log({
      monthly, firstName, lastName, email, address, zip, city, state, phone, number, expiry, cvc, unit
    });
    toast.success("Transaction Submitted!")
  }
  continue(page){
    if(page === 'Contact') { // we are moving from donation page to contact page
      const {amount} = this.state;

      if(!validateDonation(amount)){
        return;
      }
      else {
        //process something
      }

      setTimeout(()=>{try { document.getElementById('firstName').focus()}catch(err){}},200);
    }
    if(page === 'Payment') { //we are moving from contact page to payment page
      const {firstName, lastName, email, address, zip, city, state, phone} = this.state;

      if(!validateContact(firstName, lastName, email, address, zip, city, state, phone)){
        return;
      }
      else {
        //process something
      }

      setTimeout(()=>{try { document.getElementById('card-number').focus()}catch(err){}},200);
    }

    this.setState({page});
  }
  back(page){
    console.log({page});
    this.setState({page});
  }
  setAmount(amount){
    document.getElementById('other').value = '';
    this.setState({amount})
  }
  setMonthly(){
    this.setState({monthly:!this.state.monthly})
  }
  renderDonation(){
    let bold = (amount)=> {
      return {fontWeight:this.state.amount === amount ? 'bold' : '100',color:this.state.amount === amount ? 'black': 'grey'}
    }
    return (
      <Row>
        <Col xs={4} style={{marginTop:'10px',marginBottom:'20px'}}> <button style={bold(50)} className="grey-button" onClick={this.setAmount.bind(this,50)}> $50 </button> </Col>
        <Col xs={4} style={{marginTop:'10px'}} > <button style={bold(100)} className="grey-button" onClick={this.setAmount.bind(this,100)}> $100 </button> </Col>
        <Col xs={4} style={{marginTop:'10px'}} > <button style={bold(200)} className="grey-button" onClick={this.setAmount.bind(this,200)}> $200 </button> </Col>
        <Col xs={4} style={{marginBottom:'20px'}}> <button style={bold(500)} className="grey-button" onClick={this.setAmount.bind(this,500)}> $500 </button> </Col>
        <Col xs={4}> <button className="grey-button" style={bold(1000)} onClick={this.setAmount.bind(this,1000)}> $1,000 </button> </Col>
        <Col xs={4}> <button className="grey-button" style={bold(5000)} onClick={this.setAmount.bind(this,5000)}> $5,000 </button> </Col>
        <Col xs={4}> <button className="grey-button" style={bold(10000)} onClick={this.setAmount.bind(this,10000)}> $10,000 </button> </Col>
        <Col xs={4}> <button className="grey-button" style={bold(20000)} onClick={this.setAmount.bind(this,20000)}> $20,000 </button> </Col>
        <Col xs={4}>
          <input id='other' type="number" name="amount" className="credit-card-input" placeholder="Other"
            required style={{paddingLeft:'10px',width:'100%',height:'100%',border:'1px solid grey'}} onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
        </Col>
        <Col xs={12}>
          <div style={{border:'1px solid grey',marginTop:'20px',height:'40px',background:'white',cursor:'pointer'}} onClick={this.setMonthly.bind(this)}>
            <input checked={this.state.monthly} id='other' type="checkbox" name="monthly" className="credit-card-input" placeholder="Monthly"
              required style={{float:'left',marginTop:'12px',marginLeft:'10px',border:'1px solid grey'}} />
            <p style={{marginTop:'8px',marginLeft:'30px'}}> Make this a Monthly Donation </p>
          </div>
        </Col>
      </Row>
    )
  }
  renderAddress() {
    try {
      const {search, address} = this.state
      console.log({search,address});
      return (
          <ReactGoogleMapLoader
              params={{ key: MY_API_KEY, libraries: "places,geocode"}}
              render={googleMaps => googleMaps && (
                      <ReactGooglePlacesSuggest googleMaps={googleMaps} autocompletionRequest={{input: search}} onSelectSuggest={this.handleSelectSuggest}>
                        <input style={{border:'none',paddingLeft:'10px',width:'100%'}} type="text" value={address} placeholder="Address" onChange={this.handleMapsChange}/>
                      </ReactGooglePlacesSuggest>
                  )
              }
          />
      )
    }
    catch(err){
      console.log({err});
    }
  }
  renderContact(){
    let {firstName, lastName, email, address, zip, city, state, phone, unit} = this.state;
    // <input value={address} type="text" name="address" className="credit-card-input" placeholder="Address"
    //   required style={{border:'none',paddingLeft:'10px',width:'100%'}} onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
    return (
      <Row>
        <div className="App-payment">
          <form>
            <Row>
              <div className="col-6 center" style={{paddingTop:'10px',paddingBottom:'10px'}}>
                <div className="form-group">
                  <input id='firstName' value={firstName} type="text" name="firstName" className="credit-card-input" placeholder="First Name"
                    required style={{border:'none',paddingLeft:'10px',width:'100%'}} onChange={this.handleInputChange} onFocus={this.handleInputFocus}/>
                </div>
              </div>
              <div className="col-6 center" style={{paddingTop:'10px',paddingBottom:'10px'}}>
                <div className="form-group">
                  <input value={lastName} type="text" name="lastName" className="credit-card-input" placeholder="Last Name"
                    required style={{border:'none',paddingLeft:'10px',width:'100%'}} onChange={this.handleInputChange} onFocus={this.handleInputFocus}/>
                </div>
              </div>
              <div className="col-12 center" style={{paddingTop:'10px',paddingBottom:'10px'}}>
                <div className="form-group">
                  <input value={email} type="email" name="email" className="credit-card-input" placeholder="Email"
                    required style={{border:'none',paddingLeft:'10px',width:'100%'}} onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
                </div>
              </div>
              <div className="col-8 center" style={{paddingTop:'10px',paddingBottom:'10px'}}>
                <div className="form-group">
                  {this.renderAddress()}
                </div>
              </div>
              <div className="col-4 center" style={{paddingTop:'10px',paddingBottom:'10px'}}>
                <div className="form-group">
                  <input value={unit} type="text" name="unit" className="credit-card-input" placeholder="Unit"
                    required style={{border:'none',paddingLeft:'10px',width:'100%'}} onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
                </div>
              </div>
              <div className="col-6 center" style={{paddingTop:'10px',paddingBottom:'10px'}}>
                <div className="form-group">
                  <input value={city} type="text" name="city" className="credit-card-input" placeholder="City"
                    required style={{border:'none',paddingLeft:'10px',width:'100%'}} onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
                </div>
              </div>
              <div className="col-3 center" style={{paddingTop:'10px',paddingBottom:'10px'}}>
                <div className="form-group">
                  <input value={state} type="text" name="state" className="credit-card-input" placeholder="State"
                    required style={{border:'none',paddingLeft:'10px',width:'100%'}} onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
                </div>
              </div>
              <div className="col-3 center" style={{paddingTop:'10px',paddingBottom:'10px'}}>
                <div className="form-group">
                  <input value={zip} type="number" name="zip" className="credit-card-input"placeholder="Zip"
                    required style={{border:'none',paddingLeft:'10px',width:'100%'}} onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
                </div>
              </div>

              <div className="col-12 center" style={{paddingTop:'10px',paddingBottom:'10px'}}>
                <div className="form-group">
                  <input value={phone} type="tel" name="phone" className="credit-card-input" placeholder="Phone"
                    required style={{border:'none',paddingLeft:'10px',width:'100%'}} onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
                </div>
              </div>
            </Row>
          </form>
        </div>
      </Row>
    )
  }
  renderCard(){
    const { firstName,lastName, number, expiry, cvc, focused, issuer} = this.state;
    return (
      <Row>
        <div>
          <div className="App-payment">
            <Card number={number} name={`${firstName} ${lastName}`} expiry={expiry.replace(' / ','')} cvc={cvc} focused={focused} callback={this.handleCallback}/>
            <form>
              <div className="row" style={{paddingTop:'10px'}}>
                <CreditCardInput
                  cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
                    <input {...props} name='cvc' onChange={handleCardCVCChange(e => this.handleInputChange(e) )} />
                  )}
                  cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
                    <input {...props} name='expiry' onChange={handleCardExpiryChange(e => this.handleInputChange(e))} />
                  )}
                  cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
                    <input id='number' {...props} name='number' onChange={handleCardNumberChange(e => this.handleInputChange(e))} />
                  )}
                />
              </div>
              <input type="hidden" name="issuer" value={issuer} />
            </form>
          </div>
        </div>
      </Row>
    )
  }
  renderButton(){
    const {amount, firstName, lastName, email, address, zip, city, state, phone, number, expiry, cvc, monthly} = this.state;

    let className = 'download-button';
    let text = this.state.page === 'Payment' ? 'Submit Payment' : 'Continue';
    let func = null;

    //process what to be showed on the buton
    if(this.state.page === 'Donation'){
      if(!validateDonation(amount)) className = 'disabled-button';
      else {
        func = this.continue.bind(this,'Contact');
      }
    }
    if(this.state.page === 'Contact') {
      if(!validateContact(firstName, lastName, email, address, zip, city, state, phone)) className = 'disabled-button';
      else {
        func = this.continue.bind(this,'Payment');
      }
    }
    if(this.state.page === 'Payment') {
      if(!validateCreditCard(number, expiry, cvc, firstName, lastName)) className = 'disabled-button';
      else {
        func = this.submitPayment.bind(this);
      }
    }

    return (
      <Col xs={12}>
        <div className="form-group" style={{paddingTop:'10px'}}>
          <div className="row">
            <button className={className} onClick={func} >
              {text}
            </button>
          </div>
        </div>
      </Col>
    )
  }
  render(){
    let back = null;
    if(this.state.page === 'Contact') back = "Donation";
    if(this.state.page === 'Payment') back = "Contact";

    let bold = (page)=> {
      return {cursor:'pointer',fontWeight:this.state.page === page ? 'bold' : '100',color:this.state.page === page ? 'black': 'grey'}
    }

    return (
      <div style={{width:'400px',background:'#f2f2f2'}}>
        <Row style={{paddingRight:'20px',paddingTop:'10px',paddingLeft:'20px'}}>
          <hr style={{marginTop:'10px'}}/>
          <Col xs={4} style={{textAlign:'center'}}> <h6 style={bold('Donation')}> Donation Amount </h6> </Col>
          <Col xs={4} style={{textAlign:'center'}}> <h6 style={bold('Contact')}> Contact Information </h6> </Col>
          <Col xs={4} style={{textAlign:'center'}}> <h6 style={bold('Payment')}> Payment Confirmation </h6> </Col>
          <hr style={{marginTop:'10px'}}/>
        </Row>

        <Row style={{paddingLeft:'20px',paddingRight:'20px'}}>
          <Col xs={12} style={{height:'250px'}}>
            {this.state.page === 'Donation' && this.renderDonation()}
            {this.state.page === 'Contact' && this.renderContact()}
            {this.state.page === 'Payment' && this.renderCard()}
          </Col>
          {this.renderButton()}
          <hr style={{marginTop:'20px'}}/>
          <Col xs={3}>{back != null && <p style={{ margin: "auto",width:'100%',cursor:'pointer'}} onClick={this.back.bind(this,back)}> Back </p>}</Col>
          <Col xs={9}> <p style={{textAlign:'right'}}> Donating ${Intl.NumberFormat().format(this.state.amount)} {this.state.monthly ? " Monthly" : null} </p> </Col>
          <hr/>
        </Row>

        <ToastContainer />
      </div>
    );
  }
}
