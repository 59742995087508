import React, {Component} from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

const MY_API_KEY = "AIzaSyAFpoWSkM02BB5ORBgJ1RiIqype-mI-XqY" // fake

export default class GoogleSuggest extends React.Component {
    state = {
        search: "",
        value: "",
    }
    handleInputChange = e => {
        this.setState({search: e.target.value, value: e.target.value})
    }
    handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
      try {
        let address = geocodedPrediction.address_components[0].long_name + ' ' + geocodedPrediction.address_components[1].long_name;
        let state = geocodedPrediction.address_components[5].short_name;
        let city = geocodedPrediction.address_components[3].long_name;
        let zip = geocodedPrediction.address_components[7].long_name;
        this.setState({address, zip, city, state})
      }
      catch(err){

      }
    }
    render() {
        const {search, value} = this.state
        return (
            <ReactGoogleMapLoader
                params={{ key: MY_API_KEY, libraries: "places,geocode"}}
                render={googleMaps => googleMaps && (
                        <ReactGooglePlacesSuggest googleMaps={googleMaps} autocompletionRequest={{input: search}}
                            onSelectSuggest={this.handleSelectSuggest} textNoResults={null}>
                          <input type="text" value={value} placeholder="Search a location" onChange={this.handleInputChange}/>
                        </ReactGooglePlacesSuggest>
                    )
                }
            />
        )
    }
}
